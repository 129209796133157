import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { format, parse } from 'date-fns';
import queryString from 'query-string';
import { Box, Text, Flex, Divider, Icon, Link, BaseButton, BaseButtonProps, styled, IconProps, FlexProps } from '@raytion/sri-frontend-ui-components';
import TaggingDialog from "./TaggingDialog";
import Preview from './Preview';
import Tooltip from './Tooltip';
import copy from 'copy-to-clipboard';
import {useTagList} from '../context/TagListContext'
import { useParams } from "react-router-dom";
import { useAppState } from '../context/AppProvider'
import url from '../config/url'

type ResultProps = {
  result: any
}

const Result:React.FC <ResultProps> = ({result}) => {
  const [wasCopied, setWasCopied] = useState(false);
  const [voted, setVoted] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [date, setDate] = useState<string | undefined>(result.sri_custom_date);
  const [redacted, setRedacted] = useState<boolean>(result.redacted);
  const [confidential, setConfidential] = useState<boolean>(result.confidential);
  const [privileged, setPrivileged] = useState<boolean>(result.confidential);
  const [tagList, setTagList] = useState((result.tag_values || []) as string[])
  const [projectTagList, fetchProjectTagList] = useTagList();
  const [showDialog, setShowDialog] = useState(false);
  const [blob, setBlob] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const {project} = useParams();
  const [appState] = useAppState();

  const filteredProjectTagList = projectTagList ? projectTagList.filter(item => !tagList.includes(item)) : [];
  const documentURI: string = result.url || '';
  const downloadParams = `frontend=${project}&uri=${encodeURIComponent(documentURI)}`
  const downloadLink = `/download?${downloadParams}`
  
  const getUserFeedback = (userScore: number) => {
    if(userScore < 0.25){
      return "irrelevant"
    }
    if(userScore < 0.5){
      return "related"
    }
    if(userScore < 0.75){
      return "relevant"
    }
    if(userScore <= 1){
      return "important"
    }
    return ''
  }

  const user_feedback: string = result.user_score ? getUserFeedback(parseFloat(result.user_score)): '';

  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const handleRemoveTag = (tag: string) => {
    setTagList(tags => tags.filter(t => t !== tag));
    removeTag(tag)
  }

  const handleAddTag = (tag: string) => {
    if(!tagList.includes(tag)){
      setTagList(tagList => {
        return [...tagList, tag]
      });
      addTag(tag)
    }
  }

  const handleSubmitDate = (date: Date | undefined) => {
    if(date){
      const dateString = format(date, 'yyyy/MM/dd')
      setDate(dateString)
      addDate(dateString)
    }
    else{
      setDate(undefined)
      removeDate()
    }
  }

  const removeTag = (tag: string) => {
    fetch(`${url}tag`, 
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: ` Bearer ${appState.graphToken}`
        },
        method: "DELETE",
        body: queryString.stringify({frontend: project, document: documentURI, tag: tag, })
      })
      .then(function(res){ console.log(res) })
      .catch(function(res){ console.log(res) })
  }

  const addTag = (tag: string) => {
    fetch(`${url}tag?frontend=${project}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: ` Bearer ${appState.graphToken}`
        },
        method: "POST",
        body: queryString.stringify({frontend: project, document: documentURI, tag: tag, })
      })
      .then(function(res){ 
        fetchProjectTagList()
      })
      .catch(function(res){ console.log(res) })
  }

  const addDate= (date: string) => {
    fetch(`${url}tag/date`, 
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: ` Bearer ${appState.graphToken}`
        },
        method: "POST",
        body: queryString.stringify({frontend: project, document: documentURI, date, })
      })
      .then(function(res){ console.log(res) })
      .catch(function(res){ console.log(res) })
  }

  const removeDate = () => {
    fetch(`${url}tag/date`, 
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: ` Bearer ${appState.graphToken}`
        },
        method: "DELETE",
        body: queryString.stringify({frontend: project, document: documentURI, date })
      })
      .then(function(res){ console.log(res) })
      .catch(function(res){ console.log(res) })
  }


  const getIconName = (fileExtension: string): string => {
    let extension = fileExtension && fileExtension ? fileExtension : 'default';
    switch(extension) {
      case 'pdf':
        return 'fal fa-file-pdf';
      case 'docx':
        return 'fal fa-file-word';
      case 'ppt':
      case 'pptx':
        return 'fal fa-file-powerpoint';
      case 'xlsx':
        return 'fal fa-file-excel';
      case 'png':
        return 'fal fa-file-image';
      default:
        return 'fal fa-file';
    }
  }

  const handleDownload = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    window.location.assign(`${url}download?${downloadParams}&access_token=${appState.graphToken}`);
    e.preventDefault()
  }

  const handleCopyLink = () => {
    copy(`${window.location.origin}${downloadLink}`);
    setWasCopied(true);
    setTimeout(() => setWasCopied(false), 600);
  }

  const handleVoting = (feedback: "like" | "dislike") => {
    fetch(`${url}${feedback}?frontend=${project}`,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: ` Bearer ${appState.graphToken}`
      },
      method: "POST",
      body: queryString.stringify({ document: documentURI })
    })
    .catch(function(res){ console.log(res) })
    setVoted(true)
  }

  const handleBooleanTag = (endpoint: "redacted" | "confidential" | "privileged", value: boolean) => {
    fetch(`${url}tag/${endpoint}?frontend=${project}`,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: ` Bearer ${appState.graphToken}`
      },
      method: "POST",
      body: queryString.stringify({ document: documentURI, boolean: value})
    })
    .catch(function(res){ console.log(res) })
    switch(endpoint) {
      case 'redacted':
        setRedacted(value)
        return;
      case 'confidential':
        setConfidential(value)
        return;
      case 'privileged':
        setPrivileged(value)
        return;
      default:
        return;
    }
  }
  
  return (
    <Box mb={3}>
      <Flex>
        <Preview url={result.url} handleTabsChange={handleTabsChange} open={open} setBlob={setBlob} setShowSpinner={setShowSpinner} showSpinner={showSpinner}/>
        <Box ml={4} flexGrow={1}>
          <Flex justifyContent="space-between">
            <Box>
              <Text textColor="primary" variant="meta">
                {'ID: ' + (result.pretty_id ? result.pretty_id : '')}
              </Text>
              {result.title && (
                <Flex my={1} mr={4} alignItems="center">
                  <Link onClick={handleDownload} target="_blank" as={RouterLink} to={downloadLink}>
                    <Text variant="text" fontWeight={700}>
                      {result.title}
                    </Text>
                  </Link>
                  <Tooltip label={wasCopied ? 'Copied link' : 'Copy link'} ariaLabel="copy link">
                    <div>
                      <StyledButton onClick={handleCopyLink} ml={1}>
                        <Icon color="primary" className="fal fa-copy" />
                      </StyledButton>
                    </div>
                  </Tooltip>
                </Flex>
              )}
            </Box>
            <Icon size={20} color="primary" className={getIconName(result.file_extension2)}/>
          </Flex>
          {result.text && (
            <Text variant="text">
              {result.text}
            </Text>
          )}
          <Flex mt={3} flexWrap="wrap">
            {
              result.attachments && result.attachments.map((attachment: { title: string, urlSuffix: string }) => (
                <Attachment value={attachment.title} suffix={attachment.urlSuffix} link={result.messageUrl} />
              ))
            }
          </Flex>
          <Flex mt={3} flexWrap="wrap">
            {result.lastmodified && (
              <Tag label="Upload date" values={[result.lastmodified]} />
            )}
            {result.sent_date && (
              <Tag label="Sent date" values={[result.sent_date]} />
            )}
            {result.author2 && (
              <Tag label="Author" values={[result.author2]} />
            )}
            {result.file_extension2 && (
              <Tag label="File extension" values={[result.file_extension2]} />
            )}
            <Tag label="Tags" values={tagList} onEdit={()=> { handleTabsChange(1); open() }}/>
            <Tag label="Custom date" values={[(date || '')]} onEdit={() => { handleTabsChange(2); open() }} />
            {
              result.file_size_mb &&
              <Tag label="Filesize" values={[parseInt(result.file_size_mb) < 1 ? "less than 1 MB" : result.file_size_mb + " MB"]} />
            }
            {
              user_feedback &&
              <Tag label="User Feedback" values={[user_feedback]} />
            }
          </Flex>
          <Flex mt={3} justifyContent={"space-between"}>
            <Flex>
              <BooleanTag onClick={() => handleBooleanTag("redacted", !redacted)}>
                <Icon size={14} mr={1} className={`fal fa-file-${redacted ? "signature": "alt"}`}></Icon>
                <Text variant="meta" as="span" textColor="primary" mr={2}>{redacted ? "Redacted": "Native"}</Text>
              </BooleanTag>
              <BooleanTag onClick={() => handleBooleanTag("confidential", !confidential)}>
                <Icon size={14} mr={1} className={`fal fa-${confidential ? "user-lock": "unlock"}`}></Icon>
                <Text variant="meta" as="span" textColor="primary" mr={2}>{confidential ? "Confidential": "Public"}</Text>
              </BooleanTag>
              <BooleanTag onClick={() => handleBooleanTag("privileged", !privileged)}>
                <Icon size={14} mr={1} className={`fal fa-${privileged ? "crown": "bars"}`}></Icon> 
                <Text variant="meta" as="span" textColor="primary" mr={2}>{privileged ? "Privileged": "Unprivileged"}</Text>
              </BooleanTag>
            </Flex>
            {
              !voted && 
              <Flex>
                <Text textColor="primary" variant="meta" mt="3px">
                    User Feedback:
                </Text>
                <Box onClick={() => handleVoting("like")}>
                  <VotingButton voted={voted} size={14} ml={2} className="fal fa-thumbs-up"></VotingButton>
                </Box>
                <Box onClick={() => handleVoting("dislike")} mt="2px">
                  <VotingButton voted={voted} size={14} ml={2} className="fal fa-thumbs-down"></VotingButton>
                </Box>
              </Flex>
            }
            {
              voted &&
              <Flex>
                <Text textColor="primary" variant="meta" mt="3px">
                    Thank you for your feedback!
                </Text>
              </Flex>
            }
          </Flex>
        </Box>
      </Flex>
      <TaggingDialog 
        title={result.title}
        isOpen={showDialog}
        blob={blob}
        onDismiss={close}
        tagList={tagList}
        tagSuggestions={filteredProjectTagList}
        onAddTag={handleAddTag}
        onRemoveTag={handleRemoveTag}
        onSubmitDate={handleSubmitDate}
        defaultDate={date ? parse(date, 'yyyy/MM/dd', new Date()) : undefined}
        tabIndex={tabIndex}
        handleTabsChange={handleTabsChange}
        showSpinner={showSpinner}
        setShowSpinner={setShowSpinner}
      />
      <Divider mt={4} color="#E0E4F2" />
    </Box>
  )
}

export default Result

type TagProps = {
  label: string,
  values: string[],
  onEdit?: ()=>void
}

const Tag: React.FC<TagProps> = ({label, values, onEdit}) => {
  return(
    <Flex mr={4}>
    <Text py="2px" variant="meta" textColor="primary">
      <Text mr={1} as="span"  variant="meta" textColor="primary" fontWeight={600}>
        {label}
      </Text>
      {values.length > 0 && values.map((value, index) => (
        index < values.length - 1 ? value + ' | ' : value
      ))}
    </Text>
    {onEdit && (
      <EditButton ml={1} onClick={onEdit} />
    )}
    </Flex>
  )
}

type AttachmentProps = {
  value: string,
  suffix: string,
  link: string
}

const Attachment: React.FC<AttachmentProps> = ({ value, suffix, link }) => {
  const { project } = useParams();
  const [appState] = useAppState();
  
  const downloadParams = `frontend=${project}&uri=${encodeURIComponent(link + "/" + suffix)}`
  const downloadLink = `/download?${downloadParams}`

  const handleDownload = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    window.location.assign(`${url}download?${downloadParams}&access_token=${appState.graphToken}`);
    e.preventDefault()
  }
  
  return(
    <Link onClick={handleDownload} target="_blank" as={RouterLink} to={downloadLink} mr={4}>
      <Text py="2px" variant="meta" textColor="primary">
      <Icon size={10} mr={1} className="fal fa-link" />
        {value}
      </Text>
    </Link>
  )
}


const EditButton: React.FC <BaseButtonProps> = (props) => (
  <StyledButton ml={1} {...props}>
    <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons/edit/pen/pen-circle" fill="currentColor">
          <path d="M10.2218701,3.42604697 L10.773964,3.97814092 C11.1420267,4.34856293 11.1420267,4.94548502 10.773964,5.31354765 L5.57625904,10.5112526 L3.89402406,10.7000027 C3.66752398,10.7235965 3.47641454,10.5348464 3.50236767,10.3083463 L3.68875836,8.62375196 L8.88646335,3.42604697 C9.25688536,3.05798434 9.85380745,3.05798434 10.2218701,3.42604697 Z M5.22943079,9.78928363 L9.06577591,5.94821975 L8.2470725,5.12951633 L4.40836799,8.96822084 L4.30691483,9.89073679 L5.22943079,9.78928363 Z M10.2407451,4.78032871 C10.3138857,4.70718806 10.3138857,4.58685989 10.2407451,4.51371924 L9.68865114,3.96162529 C9.61551049,3.88848464 9.49518232,3.88848464 9.42204167,3.96162529 L8.78501019,4.59865677 L9.60371361,5.41736019 L10.2407451,4.78032871 Z M7,0.903225806 C3.62989516,0.903225806 0.903225806,3.63539919 0.903225806,7 C0.903225806,10.3712339 3.63424194,13.0967742 7,13.0967742 C10.369879,13.0967742 13.0967742,10.3670847 13.0967742,7 C13.0967742,3.61502016 10.3481734,0.903225806 7,0.903225806 Z M7,0 C10.8657218,0 14,3.13540726 14,7 C14,10.8668508 10.8657218,14 7,14 C3.13427823,14 0,10.8668508 0,7 C0,3.13540726 3.13427823,0 7,0 Z" id="Combined-Shape"></path>
        </g>
      </g>
    </svg>
  </StyledButton>
)


const StyledButton = styled(BaseButton)(
  ({theme}) => ({
    color: '#395a835c',
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.colors.primary,
      backgroundColor: 'transparent',
    }
  })
)

const BooleanTag = styled(Flex)<FlexProps>(
  ({theme}) => ({
    cursor: "pointer"
  })
)

const VotingButton = styled(Icon)<IconProps & { voted: boolean }>(
  ({theme, voted}) => ({
    cursor: voted? "not-allowed": "pointer"
  })
)