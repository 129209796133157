/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useEffect } from 'react';
import { useParams as reactParams } from "react-router-dom";
import { Flex, Box, Text, useResponseState, Divider, useTranslation, useIsLoading, Alert, useActivateSearch, useBackend, SelectOption, Select, styled, useParams } from '@raytion/sri-frontend-ui-components';
import Result from '../components/Result'
import Pagination from '../components/Pagination'
import Heroimage from '../components/Heroimage'
import Searchbox from '../components/Searchbox'
import Notifications from '../components/Notifications';
import SingleNavigator from '../components/SingleNavigator';
import BooleanNavigator from '../components/BooleanNavigator';
import SourceNavigator from '../components/SourceNavigator';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {TagListProvider} from '../context/TagListContext';
import Spinner from '../components/Spinner'
import DateNavigator from '../components/DateNavigator';
import hero from '../assets/hero.png'
import ExportButton from '../components/ExportButton';
import { VerticalNav } from '../components/VerticalNav';
import { useAppState } from '../context/AppProvider'
import { Config } from '../config-types'

type SearchProps = { config: Config, displayName: string }

const sortOptions = [{
  label: "Relevancy",
  value: "default"
}, {
  label: "Upload Date",
  value: "document_sri_lastmodifieddate"
}]

const sortOptions_doc = [{
  label: "Relevancy",
  value: "default"
}, {
  label: "Upload date",
  value: "document_sri_lastmodifieddate"
}]

const sortOptions_mail = [{
  label: "Relevancy",
  value: "default"
}, {
  label: "Sent date",
  value: "imap_mail_mail_date"
}, {
  label: "Upload date",
  value: "document_doc_date"
}]

const Search: React.FC <SearchProps> = ({ config, displayName  }) => {
  const activateSearch =  useActivateSearch()
  const [params, setParams] = useParams()
  const [ backend ] = useBackend()
  const isLoading = useIsLoading()
  const { searchResponse: data } = useResponseState();
  const [ appState ] = useAppState();
  const t = useTranslation()
  const {project} = reactParams()

  const tabs = [{
    title: "Documents",
    backend: project + "_doc"
  }, {
    title: "Mails",
    backend: project + "_mail"
  }]
  
  const handleSort = (value: string) => {
    setParams({sort: value !== "default" ? value: ""})
  }

  useEffect(()=>{
    activateSearch()
    return () => activateSearch(false)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <TagListProvider>
    <Flex flexDirection="column" css={{minHeight: '100vh'}}>
      <Header config={config} displayName={displayName} />
      <Box flexGrow={1}>
        <Heroimage url={hero}>
          {/* <Box backgroundColor={'#000'} style={{opacity: 0.2, position: "absolute", height: 155, width: "100%", zIndex: 1}}> */}
          {/* </Box> */}
          <Box css={{margin: '0 auto', maxWidth: 1180, zIndex: 10, position: "relative"}} pt={"40px"} pb={"40px"} px={8}>
            {<Searchbox />}
          </Box>
        </Heroimage>
        <Flex css={{margin: '0 auto', maxWidth: 1180}} px={8}>
          <Box width={250} flexShrink={0} mr={6}>
            <Box mt={6} >
              <Box css={{height: 24}}>
                <Text fontSize={16} fontWeight={300} css={{textTransform: 'uppercase'}}>
                  Filter by
                </Text>
              </Box>
              <Divider backgroundColor="E0E4F2" mb={4} mt={2}  />
              <SourceNavigator field="source" title="Source" /> 
              <SingleNavigator field="tags" title="Tags" />
              {
                (!backend || !backend.includes("_mail")) && 
                <SingleNavigator field="filetype" title="File extension" />
              }
              <SingleNavigator field="author" title="Author " />
              <SingleNavigator excludeOption={false} title="User Feedback" field="user_feedback" />
              <BooleanNavigator field="booleanTags" title="Boolean Tags" />
              {data.query && (
                <React.Fragment>
                  {
                    backend && backend.includes("_mail") ? (
                      <React.Fragment>
                        <DateNavigator title="Sent date" field="sent_date" />
                        <DateNavigator title="Upload date" field="lastmodified" />
                      </React.Fragment>
                    ) : (
                      <DateNavigator title="Upload date" field="lastmodified" />
                    )
                  }
                  <DateNavigator title="Custom date" field="customdate" />
                </React.Fragment>
              )}
            </Box>
          </Box>
          <Box  flexGrow={1}>
            <Box mt={6} >
              <Flex justifyContent="space-between"  css={{height: 24}}>
                <Text fontSize={16} fontWeight={300} css={{textTransform: 'uppercase'}}>
                  {data.resultcounts ? t('%1 results for %2', [data.resultcounts.all_retrievable, data.query.user]) : ''}
                </Text>
                <Flex>
                  <Flex>
                    <Text>
                      Sort by: 
                    </Text>
                    <StyledSelect value={!params.sort ? "default": params.sort} onChange={handleSort}>
                      {!backend && sortOptions.map(option => (
                        <StyledSelectOption value={option.value}>
                          {option.label}
                        </StyledSelectOption>
                      ))}
                      {backend === project + "_doc" && sortOptions_doc.map(option => (
                        <StyledSelectOption value={option.value}>
                          {option.label}
                        </StyledSelectOption>
                      ))}
                      {backend === project + "_mail" && sortOptions_mail.map(option => (
                        <StyledSelectOption value={option.value}>
                          {option.label}
                        </StyledSelectOption>
                      ))}
                    </StyledSelect>
                  </Flex>
                  <Text fontSize="24px" lineHeight="25px" fontWeight="50" textColor="#E0E4F2" mr={3}>
                    |
                  </Text>
                  {data.results && (
                    <ExportButton />
                  )}
                </Flex>
              </Flex>
              <Divider backgroundColor="E0E4F2" mb={2} mt={2} />
              <Flex pb="30px">
                {/* <Box width={250} flexShrink={0} mr={6}>
                </Box> */}
                <VerticalNav resultTabs={tabs}></VerticalNav>
              </Flex>
              <Notifications data={data} dym={data.query && data.query.dym} />
              {appState.error && (
                <Alert >
                  {appState.error}
                </Alert>
              )}
              <Box css={{position: 'relative'}}>
                {isLoading && (
                  <Flex
                    css={{
                      position: 'absolute',
                      top: 0,
                      left:0,
                      right: 0,
                      bottom: 0,
                      paddingTop: 80,
                      justifyContent: 'center',
                      backgroundColor: 'rgba(255,255,255,0.6)',
                    }}
                  >
                    <Spinner />
                  </Flex>
                )}
                { data && data.results && data.results.map( (result: any) => (
                  <Result key={result.url} result={result}/>
                ))}
              </Box>
            </Box>
            <Flex justifyContent="center" mt={6} mb={8}>
              { data && data.resultcounts && (data.resultcounts.next_start || data.resultcounts.start > 10 ) && (
                <Pagination  />
              )}
            </Flex>
          </Box>
        </Flex>        
      </Box>
      <Footer />
    </Flex>
    </TagListProvider>
  );
}


// const StyledButton = styled(SelectOption)(({ theme }) =>
//   ({
//     ...theme.textStyles.text,
//     color: theme.colors.text,
//     backgroundColor: 'transparent',
//     '&:hover': {
//       backgroundColor: 'transparent',
//       color: theme.colors.primary,
//     },
//     '&:active': {
//       backgroundColor: 'transparent',
//       color: theme.colors.primary,
//     },
//     '&:disabled': {
//       color: theme.colors.textDisabled,
//       backgroundColor: 'transparent',
//     },
//     '&[aria-expanded="true"]': {
//       backgroundColor: 'transparent',
//       color: theme.colors.primary,
//     },
//   })
// )

const StyledSelect = styled(Select)(({ theme }) =>
  ({
    ...theme.textStyles.text,
    color: theme.colors.text,
    marginLeft: 7,
    marginRight: 15,
    '& span': {
      ...theme.textStyles.text,
      color: theme.colors.text,
    }
  })
)

const StyledSelectOption = styled(SelectOption)(({ theme }) =>
  ({
    ...theme.textStyles.text,
    color: theme.colors.text,
    '& li': {
      ...theme.textStyles.text,
      color: theme.colors.text,
    },
    '&:hover': {
      backgroundColor: '#E0E4F2',
    },
    '&[data-highlighted=""]': {
      backgroundColor: '#E0E4F2',
      color: theme.colors.text
    },
    '&[aria-selected="true"]': {
      ...theme.textStyles.text,
      color: theme.colors.text
    },
  })
)

// const StyledItem = styled(MenuItem)(({ theme }) =>
//   ({
//     ...theme.textStyles.text,
//     color: theme.colors.text,
//     backgroundColor: 'transparent',

//     '&:hover': {
//       color: theme.colors.primary,
//     },
//   })
// )

export default Search;
